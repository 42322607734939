




























































































































































import StatisticsService from "@/services/StatisticsService";
import LocationService from "@/services/LocationService";
import { Component, Vue, Watch } from "vue-property-decorator";
import router from "../router/index";
import moment from "moment";

@Component
export default class Statistics extends Vue {
  private locationUuid: string = "";
  private locations: any[] = [];
  private events: any[] = [];
  private currentPage: number = 1;
  private pagesize: number = 10;
  private sortBy = "locationName,eventName";
  private sortDesc = false;
  private sortDirection = "asc";

  private datacollection: any = {
    labels: [
      "week 1",
      "week 2",
      "week 3",
      "week 4",
      "week 5",
      "week 6",
      "week 7",
      "week 8",
      "week 9",
      "week 10",
    ],
    datasets: [
      {
        data: [86, 114, 106, 106, 107, 111, 133, 221, 783, 2478],
        label: "Africa",
        borderColor: "#3e95cd",
        fill: false,
      },
      {
        data: [282, 350, 411, 502, 635, 809, 947, 1402, 3700, 5267],
        label: "Asia",
        borderColor: "#8e5ea2",
        fill: false,
      },
      {
        data: [168, 170, 178, 190, 203, 276, 408, 547, 675, 734],
        label: "Europe",
        borderColor: "#3cba9f",
        fill: false,
      },
      {
        data: [40, 20, 10, 16, 24, 38, 74, 167, 508, 784],
        label: "Latin America",
        borderColor: "#e8c3b9",
        fill: false,
      },
      {
        data: [6, 3, 2, 2, 7, 26, 82, 172, 312, 433],
        label: "North America",
        borderColor: "#c45850",
        fill: false,
      },
    ],
  };
  private chartOptions: any = {
    responsive: false,
    legend: {
      display: true,
    },
    tooltips: {
      titleFontSize: 20,
      bodyFontSize: 25,
    },
    scales: {
      xAxes: [],
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
          },
        },
      ],
    },
  };

  handlePageSizeChange() {
    this.currentPage = 1;
    this.handlePageChange();
  }

  sortChanged(data: any) {
    this.sortBy = data.sortBy;
    this.sortDesc = data.sortDesc;
    this.sortDirection = data.sortDesc ? "desc" : "asc";
    this.currentPage = 1;
    this.handlePageChange();
  }

  @Watch("currentPage")
  handlePageChange() {
    this.reload();
  }

  reload() {
    StatisticsService.search(
      (this.locations.filter((l: any) => l.uuid == this.locationUuid)[0] || {})
        .name,
      this.currentPage - 1,
      this.pagesize,
      `${this.sortBy},${this.sortDirection}`
    ).then((response) => {
      this.events = response.data;
    });
  }

  download() {
    StatisticsService.download(this.locationUuid).then((response: any) => {
      var downloadURL = URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" })
      );
      const anchor = document.createElement("a");
      anchor.download = `${moment(new Date()).format(
        "YYMMDD-HHmmss"
      )}-statistics.xlsx`;
      anchor.href = downloadURL;
      anchor.target = "_blank";
      anchor.click();
    });
  }

  mounted() {
    moment.locale("de");
    moment.weekdays(true);
    LocationService.all("name").then((loc) => {
      this.locations = loc.data.content;
      this.reload();
    });
  }
}
