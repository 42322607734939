var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-md-3"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Ort","label-for":"location","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"7"}},[_c('b-form-select',{attrs:{"id":"location","text-field":"name","value-field":"uuid","options":_vm.locations},on:{"change":_vm.reload},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":""}},[_vm._v("Alle")])]},proxy:true}]),model:{value:(_vm.locationUuid),callback:function ($$v) {_vm.locationUuid=$$v},expression:"locationUuid"}})],1)],1)]),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col"},[_c('b-pagination',{attrs:{"total-rows":_vm.events.totalElements,"per-page":_vm.events.size,"aria-controls":"events"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('div',{staticClass:"col text-center"},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"btn-radios-scale","options":[
              { value: '10', text: '10' },
              { value: '25', text: '25' },
              { value: '50', text: '50' },
              { value: '100', text: '100' } ],"aria-describedby":ariaDescribedby,"button-variant":"outline-primary","size":"sm","name":"radio-btn-outline","buttons":""},on:{"change":_vm.handlePageSizeChange},model:{value:(_vm.pagesize),callback:function ($$v) {_vm.pagesize=$$v},expression:"pagesize"}})]}}])})],1),_c('div',{staticClass:"col text-right"},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"info"},on:{"click":function($event){return _vm.download()}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"file-earmark-excel"}}),_vm._v("Download")],1)],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md"},[_c('b-table',{attrs:{"id":"events","striped":"","hover":"","outlined":"","small":"","show-empty":"","items":_vm.events.content,"fields":[
            { key: 'locationName', label: 'Ort', sortable: true },
            { key: 'eventName', label: 'Termin', sortable: true },
            { key: 'date', label: 'Datum', sortable: true },
            { key: 'leadtime', label: 'Anmeldeschluss', sortable: true },
            { key: 'opentime', label: 'Freischaltung', sortable: true },
            { key: 'online', label: 'Online Anmeldungen', sortable: true },
            {
              key: 'officeBefore',
              label: 'Erfassungen vor Termin',
              sortable: true,
            },
            {
              key: 'officeAfter',
              label: 'Erfassungen nach Termin',
              sortable: true,
            },
            { key: 'registrations', label: 'Registrierungen' },
            { key: 'seats', label: 'Plätze', sortable: true },
            { key: 'percent', label: '%' },
            { key: 'staff', label: 'Mitarbeiter', sortable: true } ],"responsive":"sm","sort-icon-left":"","no-local-sorting":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"sort-direction":_vm.sortDirection},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"sort-changed":_vm.sortChanged},scopedSlots:_vm._u([{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("moment")(data.item.date,"DD.MM.YYYY"))+" "+_vm._s(data.item.startTime)+" ")]}},{key:"cell(leadtime)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("moment")(data.item.leadtime,"DD.MM.YYYY HH:mm"))+" ")]}},{key:"cell(registrations)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.online + data.item.officeBefore + data.item.officeAfter)+" ")]}},{key:"cell(percent)",fn:function(data){return [_vm._v(" "+_vm._s(( (100 * (data.item.online + data.item.officeBefore + data.item.officeAfter)) / data.item.seats ).toFixed(0))+"% ")]}},{key:"empty",fn:function(){return [_c('p',[_vm._v("Bitte einen Ort auswählen")])]},proxy:true}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md"},[_c('h4',[_vm._v("Statistiken")])])])}]

export { render, staticRenderFns }