import http from "../http-common";
import AuthService from "./AuthService";
import { DataService } from "./DataService";

class StatisticsService extends DataService {

  search(location: string, page: number = 0, size: number = 0, sort: string = "") {
    return http.get(`${this.url}`, { headers: { Authorization: AuthService.token() }, params: { location: location, page: page, size: size, sort: sort } });
  }

  download(uuid: string) {
    return http.request({
      method: 'get',
      url: `${this.url}/download/${uuid}`,
      headers: { Authorization: AuthService.token() },
      responseType: 'blob'
    });
  }
}

export default new StatisticsService('/statistics');